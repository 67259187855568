@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@100;300;400;700&display=swap");

body {
  margin: 0;
  font-family: "League Spartan", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #02182b;
  font-size: 1.2rem;
  line-height: 1.2;
  position: relative;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
